import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField,Select,FormControl} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';

import classNames from 'classnames';
import ReactTable from './shares/react_table';

import theme,{styles} from '../layout/theme';
import Utils from '../api/api';

export class ScheduleList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      //searchClick: false,
      courses: [],
      filter: '',
      statuses: ['Sắp khai giảng','Đã khai giảng','Đã hủy','Đã bế giảng'],
      sorts: JSON.stringify({startdate:1}),
      status: '',
      courseid: '',
      dateStart: '',
      dateEnd: '',
    }
  }
  componentDidMount(){
    const $this = this;

    // this.setState({
    //   sorts: JSON.stringify({startdate:1})
    // });

    Utils.getListData("courses", 'all', 200, "0,0", JSON.stringify({status:2}), 1, function(data) {
      if (data.status === "success") {
        $this.setState({ courses: data.results });
      }
    });
  }
  handleSearch = (e) => {
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd = () => {
    this.context.router.history.push('/calendar/add');
  }
  filterData = (e) => {
    const $this = this,
          state = $this.state;

    state[e.target.id] = e.target.value.trim();
    $this.setState(state,function(){
      $this.handleFilter();
    });
  }
  handleFilter = () => {
    let f = this.state.filter;

    if(f==='') {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if(this.state.courseid!==''){
      f.courseid = this.state.courseid;
    } else {
      if(f.courseid!==undefined) {
        delete f.courseid;
      }
    }

    if(this.state.status!==''){
      f.status = parseInt(this.state.status);
    } else {
      if(f.status!==undefined) {
        delete f.status;
      }
    }

    if(this.state.dateStart!==''){
      f.startdate = {$gte:new Date(this.state.dateStart + ' 23:59:59')};
    } else {
      if(f.startdate!==undefined && this.state.dateEnd==='') {
        delete f.startdate;
      }
    }

    if(this.state.dateEnd!==''){
      f.startdate = {$lte:new Date(this.state.dateEnd + ' 23:59:59')};
    } else {
      if(f.startdate!==undefined && this.state.dateStart==='') {
        delete f.startdate;
      }
    }

    this.setState({filter:JSON.stringify(f)});
  }

  importFromGetFlyFile = (json) => {
    //console.log(json);
    const $this = this;
    if(json.length) {
      var arr = [];
      json.map ((x,i) => {
        let obj = {
          capacity: 50,
          classnameEN: x['TÊN KHÓA HỌC'],
          classnameVN: x['TÊN KHÓA HỌC'],
          code: x['MÃ KHÓA HỌC'],
          courseid: "",
          daystudy: "2-4-6",
          enddate: "",
          lecturers: [],
          location: x['ĐỊA ĐIỂM'],
          locationURL: "",
          month: 3,
          price: x['HỌC PHÍ'],
          startdate: new Date(),
          status: 0,
          students: [],
          timestart: '09:00',
          timeend: '11:00',
          url: "",
          year: (new Date()).getFullYear(),
          getflyId: '',
          getflyLocation: '',
          getflyOrigin: '',
          getflyLecture: ''
        };
        arr.push(obj);
      });
      //console.log(arr);
      Utils._post('schedule/addMany',{schedules:arr},function(response){
        if(response.status==='success') {
          $this.handleFilter();
        }
      });
    };

  }
  render(){
    const $this = this;
    const classes = this.props.classes;
    var source = {
      thead: [{title:'Mã lớp',value:'code'},{title:'Khóa học',value: 'course'},{title:'Ngày khai giảng',value: 'opening'},{title:'Ngày học',value: 'study'},{title:'Giờ học',value: 'hours'},{title:'Giá',value: 'price'},{title:'Trạng thái',value: 'status'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          code: n.code,
          course: $this.state.courses.length&&$this.state.courses.map(x=>{return x._id==n.courseid?x.nameVN:''}),
          opening: Utils.formatDate(n.startdate),
          study: n.daystudy,
          hours: n.timestart + '-' + n.timeend,
          price: n.price,
          status: $this.state.statuses.length&&$this.state.statuses.map((x,i)=>{return i==n.status?x:''}),
        });
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Lịch khai giảng</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col6}>
                  <Typography className={classes.label}>Khóa học</Typography>
                  <Select
                    native
                    id="courseid"
                    value={$this.state.courseid}
                    onChange={this.filterData}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.courses.map((item, index) => {
                      return (
                        <option key={index + 1} value={item._id}>
                          {item.nameVN}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col6}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    id="status"
                    value={$this.state.status}
                    onChange={this.filterData}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.statuses.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col6}>
                  <TextField type="date" id="dateStart" label="Từ" className={classNames(classes.textField + ' form-control-no-bottom')} value={Utils.formatDate(this.state.dateStart)} onChange={event => this.filterData(event)}
                  margin="normal"/>
                </FormControl>
                <FormControl className={classes.col6}>
                  <TextField type="date" id="dateEnd" label="Đến" className={classNames(classes.textField + ' form-control-no-bottom')} value={Utils.formatDate(this.state.dateEnd)} onChange={event => this.filterData(event)}
                  margin="normal"/>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRow}>
                <FormControl>
                  <TextField type="search" id="keyword" label="Tìm kiếm" className={classNames(classes.textField + ' form-control-no-bottom')} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}/>
                </FormControl>
              </FormGroup>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={this.handleSearch}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={this.handleAdd}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiImport={$this.importFromGetFlyFile} acceptedTypes={['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']} apiSort={this.state.sorts} apiFilter={$this.state.filter} apiDuplicateIgnore={['students']} apiDuplicate={true} apiList='schedules' apiSingle='schedule' apiTable='schedules' router={this.context.router} dataMap={source} keyword={this.state.keyword} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

ScheduleList.propTypes = {
  classes: PropTypes.object.isRequired,
};
ScheduleList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ScheduleList);