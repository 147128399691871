import React, { Component } from "react";
import PropTypes, { func } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  Toolbar,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Tabs, Tab } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { FormGroup, FormControl } from "@material-ui/core";
import TabContainer from "./shares/react_tab_container";
import language from "../layout/language";
import ReactAutoSuggest from "./shares/react_auto_suggest";

import classNames from "classnames";
import Utils from "../api/api";
import theme, { styles } from "../layout/theme";

class ScheduleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: {
        code: "",
        classnameVN: "",
        classnameEN: "",
        //classnameKR: '',
        year: "",
        month: "",
        startdate: new Date(),
        //enddate: new Date(),
        timestart: "09:00",
        timeend: "11:00",
        daystudy: "",
        courseid: "",
        lecturers: [],
        url: "",
        location: "",
        locationURL: "",
        capacity: 50,
        students: [],
        status: 0,
        price: 0,
        getflyId: "",
        getflyLocation: "",
        getflyOrigin: "",
        getflyLecture: "",
      },
      error: {
        status: false,
        vn: false,
        en: false,
        //kr: false,
        code_error: false,
        code_duplicate: false,
        daystudy_error: false,
      },
      failed: false,
      message: "",
      isUpdate: false,
      tab: 0,
      courses: [],
      lecturers: [],
      course_lecturers: [],
      daystudy: [],
      statuses: ["Sắp khai giảng", "Đã khai giảng", "Đã hủy", "Đã bế giảng"],
      students: [],
      editCode: "",
      getflyOrigins: [],
      getflyLocations: [],
      firstLoad: true,
    };
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    if (params.id !== undefined) {
      $this.setState(
        {
          isUpdate: true,
        },
        function () {
          Utils.getSingleData("schedules", params.id, function (res) {
            //console.log(res);

            if (res.status === "success" && res.results !== undefined) {
              var data = {},
                item = res.results;
              for (var property in item) {
                if (item.hasOwnProperty(property)) {
                  //console.log(item[property]);
                  data[property] = item[property];
                }
              }

              //console.log(item);

              data._id = item._id;
              if (data.classnameVN === undefined || data.classnameVN === null)
                data.classnameVN = "";
              if (data.classnameEN === undefined || data.classnameEN === null)
                data.classnameEN = "";
              if (data.locationURL === undefined || data.locationURL === null)
                data.locationURL = "";
              if (
                data.getflyLecture === undefined ||
                data.getflyLecture === null
              )
                data.getflyLecture = "";
              if (
                data.getflyLocation === undefined ||
                data.getflyLocation === null
              )
                data.getflyLocation = "";
              if (data.getflyOrigin === undefined || data.getflyOrigin === null)
                data.getflyOrigin = "";
              if (
                !Array.isArray(data.lecturers) &&
                item.lecturers !== undefined
              )
                data.lecturers = [item.lecturers] || [];
              if (!Array.isArray(data.students) && item.students === undefined)
                data.students = [item.students] || [];

              $this.setState({
                schedule: data,
                daystudy: item.daystudy.split("-"),
                editCode: data.code,
              });
              var arr = [];
              if (item.students.length) {
                item.students.map((x) => {
                  arr.push(x);
                });
                if (arr.length) {
                  Utils.getData("account_byids", arr.join(), function (data) {
                    if (data.status === "success") {
                      $this.setState({ students: data.results });
                    }
                  });
                }
              }

              //console.log(data);
              if (data.getflyId !== undefined && data.getflyId !== "") {
                Utils.getData(
                  "getfly/schedule/detail/" + data.getflyId,
                  "",
                  function (data) {
                    console.log(data);
                  }
                );
              }
            }
          });
        }
      );
    }

    Utils.getListData(
      "courses",
      1,
      1000,
      "0,0",
      JSON.stringify({ status: 2 }),
      1,
      function (data) {
        //console.log(data);
        if (data.status === "success") {
          $this.setState({ courses: data.results });
          Utils.getListData(
            "lecturers",
            1,
            1000,
            "0,0",
            JSON.stringify({ isOnline: "false", status: 1 }),
            1,
            function (dataL) {
              //console.log(dataL);
              if (dataL.status === "success") {
                $this.setState({ lecturers: dataL.results });
              }
            }
          );
        }
      }
    );

    Utils.getData("getfly/schedule/custom", "", function (data) {
      //console.log(data);
      if (data.status === "success" && data.results.length) {
        let arr = data.results.filter((x) => x.field_name === "di_a_die_m");
        //console.log(arr);
        if (arr.length) {
          $this.setState({ getflyLocations: arr[0].options });
        }
      }
    });

    Utils.getData("getfly/schedule/origins", "", function (data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ getflyOrigins: data.results });
      }
    });
  }
  handleFieldChange(e) {
    var $this = this,
      schedule = this.state.schedule;
    schedule[e.target.id] = e.target.value;
    if (e.target.id === "startdate") {
      var d = new Date(e.target.value);
      schedule.year = d.getFullYear();
      schedule.month = d.getMonth() + 1;
      //schedule.year;
    }
    this.setState({ schedule: schedule }, function () {
      //console.log($this.state.schedule);
    });
  }
  handleValidate(e, flag) {
    var $this = this,
      schedule = this.state.schedule,
      obj = this.state.error;

    schedule[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + "_error";
    if (e.target.value.trim() === "" || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj.status = obj.code_error || obj.code_duplicate || obj.daystudy_error;

    if (e.target.id === "code") {
      let v = e.target.value.trim();
      var url = Utils.getFriendlyURL(e.target.value);
      schedule.url = url;
      Utils.checkExist("schedules", "code", v, function (data) {
        if (data.status === "success") {
          if (data.result !== null) {
            if (v !== $this.state.editCode) {
              obj.code_duplicate = true;
            }
          } else {
            obj.code_duplicate = false;
          }
          obj.status =
            obj.code_error || obj.code_duplicate || obj.daystudy_error;
          $this.setState({ schedule: schedule, error: obj }, function () {
            //console.log($this.state.error);
          });
        }
      });
    } else {
      this.setState({ schedule: schedule, error: obj });
    }
  }
  _save() {
    var $this = this;
    var data = this.state.schedule;
    //console.log(data);
    data.startdate = new Date(this.state.schedule.startdate).toISOString();
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update("schedule", data, function (res) {
        //console.log(res);
        if (res.status == "success") {
          $this.context.router.history.push("/calendar/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message),
          });
        }
      });
    } else {
      Utils._add("schedule", data, function (res) {
        if (res.status == "success") {
          $this.context.router.history.push("/calendar/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message),
          });
        }
      });
    }
  }
  handleSave(e, flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({ isUpdate: true });
    }
    var $this = this;
    var obj = this.state.error;
    if (obj.status) {
      return false;
    } else {
      obj.code_error = $this.state.schedule.code === "";
      obj.daystudy_error = $this.state.schedule.daystudy === "";
      obj.status = obj.code_error || obj.code_duplicate || obj.daystudy_error;
      //console.log(obj);
      if (!obj.status) $this._save();

      this.setState({ error: obj });
    }
  }
  handleCancel() {
    this.context.router.history.push("/calendar/");
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab: value });
  };
  // handleSelectUpdate(event) {
  //   var $this = this,
  //       schedule = this.state.schedule;
  //   schedule.courseid = event.target.value;
  //   var course = this.state.courses.filter(x=>x._id===schedule.courseid);
  //   //console.log(course);
  //   schedule.classnameVN = course[0].nameVN;
  //   schedule.classnameEN = course[0].nameEN;
  //   //schedule.classnameKR = course[0].nameKR;
  //   // var urlVN = Utils.getFriendlyURL(schedule.classnameVN);
  //   // schedule.urlVN = urlVN;

  //   // var urlEN = Utils.getFriendlyURL(schedule.classnameVN);
  //   // schedule.urlEN = urlEN;

  //   this.setState({schedule:schedule},function(){
  //     console.log($this.state.schedule);
  //   });
  // }
  handleSelectUpdateLecturer(event) {
    var $this = this,
      schedule = this.state.schedule;
    schedule.lecturerid = event.target.value;

    this.setState({ schedule: schedule }, function () {
      //console.log($this.state.schedule);
    });
  }
  handleDayStudy(event) {
    var $this = this,
      schedule = this.state.schedule,
      error = this.state.error;
    //schedule.daystudy = value.join('-');

    if (event.target.value !== "") {
      error.daystudy_error = false;
      error.status =
        error.code_error || error.code_duplicate || error.daystudy_error;

      this.setState(
        { error: error, daystudy: event.target.value },
        function () {
          schedule.daystudy = $this.state.daystudy
            .filter((x) => x !== "")
            .sort()
            .join("-");
          $this.setState({ schedule: schedule });
        }
      );
    } else {
      error.daystudy_error = true;
      error.status = true;
      this.setState({ error: error });
    }
  }
  onUpdateCourse(value, title) {
    const $this = this;
    var schedule = $this.state.schedule;

    schedule.courseid = value;

    this.setState({ schedule: schedule }, function () {
      if (!$this.state.firstLoad) {
        Utils.getSingleData("course", value, function (data) {
          if (data.status === "success") {
            //console.log(data.results.lecturers);
            var arr = [];
            $this.state.lecturers.map((x, i) => {
              data.results.lecturers.map((y, z) => {
                if (x._id === y) {
                  arr.push(x);
                }
              });
            });
            schedule.price = data.results.priceSale;
            schedule.classnameVN = data.results.nameVN;
            schedule.classnameEN = data.results.nameEN;
            $this.setState({ course_lecturers: arr, schedule: schedule });
          }
        });
      } else {
        $this.setState({ firstLoad: false });
      }
    });
  }
  onUpdateLectures(value, title) {
    const $this = this;
    var schedule = $this.state.schedule;
    if (schedule.courseid === "" && schedule.lecturers.length) {
      alert("Bạn phải chọn khóa học trước");
    } else {
      // if(schedule.lecturers.indexOf(value)===-1) {
      //   schedule.lecturers.push(value);
      //   this.setState({schedule:schedule});
      // }
      if (schedule.lecturers.indexOf(value) === -1) {
        schedule.lecturers[0] = value;

        if (value !== "") {
          Utils.getSingleData("lecturer", value, function (data) {
            console.log(data);
            if (data.status === "success") {
              schedule.getflyLecture = data.results.getflyId;
            }
            $this.setState({ schedule: schedule });
          });
        } else {
          $this.setState({ schedule: schedule });
        }
      }
    }
  }
  handleDeleteLecturer(item, index) {
    var schedule = this.state.schedule,
      lecturers = schedule.lecturers;

    lecturers.splice(index, index + 1);
    schedule.lecturers = lecturers;
    this.setState({ schedule: schedule });
  }
  render() {
    const $this = this;
    const classes = $this.props.classes;
    const value = $this.state.tab;
    const schedule = $this.state.schedule;
    var students = [];
    if ($this.state.schedule.students.length && $this.state.students.length) {
      students = $this.state.schedule.students.map((x, i) => {
        let z = $this.state.students.filter((y) => y._id == x);
        return z[0] !== undefined && z[0];
      });
    }

    var arrCourses = [],
      arrLecturers = [];

    $this.state.courses.map((x, i) => {
      arrCourses.push({ title: x.nameVN, value: x._id });
    });

    $this.state.course_lecturers.map((x, i) => {
      arrLecturers.push({ title: x.fullnameVN, value: x._id });
    });

    //console.log($this.state.course_lecturers);

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Thêm/Sửa lịch khai giảng lớp học
          </Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
          </Tabs>
        </AppBar>
        <form className={classes.form} noValidate>
          <Typography
            type="body2"
            className={classNames(
              classes.error,
              (!this.state.error.code_error ||
                !this.state.error.daystudy_error) &&
                classes.hide
            )}
          >
            {language.VN.error.require}
          </Typography>
          <Typography
            type="body2"
            className={classNames(
              classes.error,
              !this.state.error.code_duplicate && classes.hide
            )}
          >
            {language.VN.error.duplicate.replace("{%field%}", "Code")}
          </Typography>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              <Typography className={classes.label}>Khóa học</Typography>
              <FormControl className={classes.chipRow}>
                {schedule.courseid !== undefined &&
                  schedule.courseid !== null &&
                  $this.state.courses.map((y, z) => {
                    if (schedule.courseid === y._id) {
                      return (
                        <Chip
                          label={y["nameVN"]}
                          key={z}
                          className={classes.chip}
                        />
                      );
                    }
                  })}
              </FormControl>
              <ReactAutoSuggest
                value={schedule.courseid}
                className={classes.formRow}
                data={arrCourses}
                onChange={(v, t) => this.onUpdateCourse(v, t)}
              ></ReactAutoSuggest>
            </FormControl>
            <FormControl className={classes.col6}>
              <Typography className={classes.label}>Giảng viên</Typography>
              <FormControl className={classes.chipRow}>
                {schedule.lecturers !== undefined &&
                  schedule.lecturers !== null &&
                  schedule.lecturers.length > 0 &&
                  schedule.lecturers.map((item, index) =>
                    $this.state.lecturers.map((y, z) => {
                      if (item === y._id) {
                        return (
                          <Chip
                            label={y["fullnameVN"]}
                            key={z}
                            onDelete={() =>
                              this.handleDeleteLecturer(item, index)
                            }
                            className={classes.chip}
                          />
                        );
                      }
                    })
                  )}
              </FormControl>
              <ReactAutoSuggest
                value={schedule.lecturers}
                className={classes.formRow}
                data={arrLecturers}
                onChange={(v, t) => this.onUpdateLectures(v, t)}
              ></ReactAutoSuggest>
            </FormControl>
          </FormGroup>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              <TextField
                id="code"
                label="Mã lớp học"
                className={classes.textField + " form-control-no-bottom"}
                value={schedule.code}
                onChange={(event) => this.handleValidate(event)}
                onBlur={(event) => this.handleValidate(event)}
                required={true}
                error={this.state.error.code_error}
                margin="normal"
              />
            </FormControl>
            <FormControl className={classes.col6}>
              <Typography className={classes.label}>Trạng thái</Typography>
              <Select
                native
                className={classes.selectMultiLevel}
                id="status"
                value={schedule.status}
                onChange={(event) => this.handleFieldChange(event)}
              >
                <option value="" key={0}>
                  ...
                </option>
                {this.state.statuses.map((item, index) => {
                  return (
                    <option key={index + 1} value={index}>
                      {item}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </FormGroup>
          <TextField
            id="location"
            label="Địa điểm học"
            className={classes.textField}
            value={schedule.location}
            onChange={(event) => this.handleFieldChange(event)}
            margin="normal"
          />
          <TextField
            id="locationURL"
            label="Nhúng bản đồ địa điểm học"
            className={classes.textField}
            value={schedule.locationURL}
            onChange={(event) => this.handleFieldChange(event)}
            margin="normal"
          />
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              {this.state.getflyLocations !== undefined ? (
                <React.Fragment>
                  <Typography className={classes.label}>
                    Đồng bộ địa điểm với GetFly System:
                  </Typography>
                  <Select
                    native
                    id="getflyLocation"
                    value={schedule.getflyLocation}
                    onChange={(event) => this.handleFieldChange(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.getflyLocations.map((item, index) => {
                      return (
                        <option key={index + 1} value={item.list_option_id}>
                          {item.list_value}
                        </option>
                      );
                    })}
                  </Select>
                </React.Fragment>
              ) : (
                ""
              )}
            </FormControl>
            <FormControl className={classes.col6}>
              {this.state.getflyOrigins !== undefined ? (
                <React.Fragment>
                  <Typography className={classes.label}>
                    Đồng bộ xuất xứ với GetFly System:
                  </Typography>
                  <Select
                    native
                    id="getflyOrigin"
                    value={schedule.getflyOrigin}
                    onChange={(event) => this.handleFieldChange(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.getflyOrigins.map((item, index) => {
                      return (
                        <option key={index + 1} value={item.origin_id}>
                          {item.origin_name}
                        </option>
                      );
                    })}
                  </Select>
                </React.Fragment>
              ) : (
                ""
              )}
            </FormControl>
          </FormGroup>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              <TextField
                id="capacity"
                type="number"
                label="Sức chứa/Sĩ số"
                className={classes.textField}
                value={schedule.capacity}
                onChange={(event) => this.handleFieldChange(event)}
                margin="normal"
              />
            </FormControl>
            <FormControl className={classes.col6}>
              <TextField
                id="price"
                type="number"
                label="Giá"
                className={classes.textField}
                value={schedule.price}
                onChange={(event) => this.handleFieldChange(event)}
                margin="normal"
              />
            </FormControl>
          </FormGroup>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col4}>
              <TextField
                id="startdate"
                type="date"
                label="Khai giảng"
                className={classes.textField}
                value={Utils.formatDate(schedule.startdate)}
                onChange={(event) => this.handleFieldChange(event)}
              />
            </FormControl>
            <FormControl className={classes.col4}>
              <Typography className={classes.label}>Ngày học</Typography>
              <Select
                required={true}
                error={this.state.error.daystudy_error}
                multiple
                value={this.state.daystudy}
                onChange={(event) => this.handleDayStudy(event)}
                inputProps={{
                  name: "daystudy-select",
                  id: "daystudy-select",
                }}
              >
                <MenuItem value="" disabled={true}>
                  ...
                </MenuItem>
                <MenuItem
                  value="CN"
                  selected={this.state.daystudy.indexOf("CN") !== -1}
                >
                  Chủ Nhật
                </MenuItem>
                <MenuItem
                  value="2"
                  selected={this.state.daystudy.indexOf("2") !== -1}
                >
                  Thứ hai
                </MenuItem>
                <MenuItem
                  value="3"
                  selected={this.state.daystudy.indexOf("3") !== -1}
                >
                  Thứ ba
                </MenuItem>
                <MenuItem
                  value="4"
                  selected={this.state.daystudy.indexOf("4") !== -1}
                >
                  Thứ tư
                </MenuItem>
                <MenuItem
                  value="5"
                  selected={this.state.daystudy.indexOf("5") !== -1}
                >
                  Thứ năm
                </MenuItem>
                <MenuItem
                  value="6"
                  selected={this.state.daystudy.indexOf("6") !== -1}
                >
                  Thứ sáu
                </MenuItem>
                <MenuItem
                  value="7"
                  selected={this.state.daystudy.indexOf("7") !== -1}
                >
                  Thứ bảy
                </MenuItem>
              </Select>
              <TextField
                id="daystudy"
                type="hidden"
                value={schedule.daystudy}
              />
            </FormControl>
            <FormControl className={classes.col4}>
              <TextField
                id="timestart"
                label="Giờ học"
                type="time"
                value={schedule.timestart}
                onChange={(event) => this.handleFieldChange(event)}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 1800 }}
              />
            </FormControl>
            <FormControl className={classes.col4}>
              <TextField
                id="timeend"
                label="Kết thúc"
                type="time"
                value={schedule.timeend}
                onChange={(event) => this.handleFieldChange(event)}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 1800 }}
              />
            </FormControl>
          </FormGroup>
          {/* <TextField id="enddate" type="date" value={Utils.formatDate(schedule.startdate)} onChange={(event) => this.handleFieldChange(event)} margin="normal" className={classes.hide}/> */}
        </form>
        {value === 0 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              {/* <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography> */}
              <TextField
                id="classnameVN"
                label="Tên lớp (ví dụ: Lớp nail - khóa 3 - 5/2018...)"
                className={classes.textField}
                value={schedule.classnameVN}
                onChange={(event) => this.handleFieldChange(event)}
                margin="normal"
              />
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={(event) => this.handleCancel(event)}
                >
                  {language.VN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => this.handleSave(event, "vn")}
                >
                  {language.VN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              {/* <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography> */}
              <TextField
                id="classnameEN"
                label={language.EN.title}
                className={classes.textField}
                value={schedule.classnameEN}
                onChange={(event) => this.handleFieldChange(event)}
                margin="normal"
              />
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={(event) => this.handleCancel(event)}
                >
                  {language.EN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => this.handleSave(event, "en")}
                >
                  {language.EN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        <div className={classes.form}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.heading} variant="h5">
              Danh sách học viên
            </Typography>
          </Toolbar>
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>STT</TableCell>
                  <TableCell>Họ & Tên</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>SĐT</TableCell>
                  <TableCell>Ngày sinh</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((x, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{x.fullname}</TableCell>
                      <TableCell>{x.email}</TableCell>
                      <TableCell>{x.phone}</TableCell>
                      <TableCell>{x.birthday}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

ScheduleDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
ScheduleDetail.contextTypes = {
  router: PropTypes.object,
};

export default withStyles(styles)(ScheduleDetail);
