import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import {TextField, Button,Select, MenuItem, FormControl} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {styles} from '../../layout/theme';
import Utils from '../../api/api';


class ReactAutoSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      data: [],
      search: [],
      convert: [],
      value: '',
      show: false,
      isSelected: false
    };
  }

  componentDidMount() {
    const data = [];
    this.props.data.map(x=>{
      let i = {};
      i.title = Utils.removeUnicode(x.title).toLowerCase();
      i.value = x.value;
      data.push(i);
    });

    this.setState({
      data: this.props.data||[],
      convert: data||[],
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const $this = this;
    if (nextProps.value && nextProps.value !== this.state.value) {
      if (typeof nextProps.value === 'object') {
        this.setState({
          value: nextProps.value.join()
        });
      } else {
        //console.log(nextProps.value);
        this.setState({
          value: nextProps.value
        },() => {
          //console.log($this.state.value);
          $this.handleSelect(nextProps.value);
        });
      }
    }

    if (nextProps.data !== this.state.data) {
      var data = [];
      nextProps.data.map(x=>{
        let i = {};
        i.title = Utils.removeUnicode(x.title).toLowerCase();
        i.value = x.value;
        data.push(i);
      });
      this.setState({
        data: nextProps.data||[],
        convert: data||[],
      });
    }

  }

  autoSuggest = (e) => {
    const $this = this,
          arr = [];
    //console.log($this.state.data);
    if(!this.state.isSelected) {
      this.setState({text:e.target.value},function(){

        if($this.state.text.trim().length>2) {
          $this.state.convert.map(x=>{
            if(x.title!==undefined){
              x.title.indexOf($this.state.text.toLowerCase())!==-1 && arr.push(x);
            }
          });
          //console.log(arr);
          $this.setState({search:arr,show: true});
        } else if ($this.state.text.trim()==='') {
          $this.setState({value:'',search:$this.state.convert,show: true},function(){
            $this.handleSelect($this.state.value);
          });
        }
      });
    }
  }

  handleSelect = (event) => {

    const $this = this;
    const value = event.target !== undefined ? event.target.value : event;
    let title = '';

      this.state.data.map(x => {
        return x.value == value ? title = x.title : '';
      });
      //console.log(title);
      this.setState({isSelected:true,show:false},function(){
        $this.setState({text:title},function(){
          $this.props.onChange(value,title);
          $this.setState({isSelected:false});
        });
      });
    // } else {
    //   this.setState({isSelected:true,show:false},function(){
    //     $this.setState({text:''},function(){
    //       $this.props.onChange(value,'');
    //       $this.setState({isSelected:false});
    //     })
    //   });
    // }
  }
  render(){
    const $this = this,
          classes = $this.props.classes;

    return (
      <FormControl className={this.props.className + ' react-auto-suggest form-control-no-bottom'}>
        <TextField value={this.state.text} onChange={this.autoSuggest} className={classNames(classes.textField)}/>
        <Select
          className={classNames(classes.selectMultiLevel,this.state.show?'':classes.hide)}
          value={this.state.value}
          onChange={this.handleSelect}
        >
          {this.state.search.map((item, index) => {
            return (
              <MenuItem key={index + 1} value={item.value}>
                {item.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}
ReactAutoSuggest.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ReactAutoSuggest);
